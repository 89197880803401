<template>
  <div class="suggestions">
    <!-- https://swiperjs.com/element#usage -->
    <swiper-container init="false" ref="swiper-container">
      <swiper-slide v-for="(suggestion,index) in suggestions" v-bind:key="_uid+index">
        <custom-link
          :route_name="'results'"
          :wrap="'div'"
          :css_class="'suggestions__pad'"
          :before_navigation="() => {
            update_store(suggestion)
          }">
          <template #content>
            <vb-card :card="{
              image: suggestion.pad.imgSrc,
              title: suggestion.pad.title,
              text: suggestion.pad.text,
            }" />
          </template>
        </custom-link>
      </swiper-slide>
    </swiper-container>
    <vb-icon :name="'angle_left'" :size="20" class="arrow" id="prev-button" @click="swiper_instance.swiper.slidePrev()">PREV</vb-icon>
    <vb-icon :name="'angle_right'" :size="20" class="arrow" id="next-button" @click="swiper_instance.swiper.slideNext()">NEXT</vb-icon>
  </div>
</template>

<script>
export default {
  name: 'suggestions',

  props: {
    suggestions: {
      type: Array,
      default: () => { return [] },
    },
    autoplay: {
      type: Number,
      default: 0,
    },
  },

  components: {
  },

  data() {
    return {
      swiper_instance: {},
      swiper_params: {
        slidesPerView: 1,
        loop: true,
        breakpoints: {
          600: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
        },
        on: {
          init() {
          },
        },
      }      
    }
  },

  created() {
  },

  mounted() {
    const swiper_instance = this.$refs['swiper-container']
    Object.assign(swiper_instance, this.swiper_params)
    swiper_instance.initialize()
    this.swiper_instance = swiper_instance
  },

  methods: {
    update_store(params) {
      this.$store.commit('occupancy', params.search_params.occupancy)
      this.$store.commit('livebk/location', params.search_params.location)
      this.$store.commit('livebk/nights', params.search_params.nights)
      this.$store.commit('livebk/flex_dates', params.search_params.flex_dates)
      this.$store.commit('livebk/departure_airports', params.search_params.departure_airports)
      this.$store.commit('livebk/departure_date', params.search_params.departure_date)
      this.$store.commit('livebk/keyd', params.search_params.location.keyd)
      this.$store.commit('livebk/last_search', '')
      this.$store.commit('livebk/current_search', '')
      window.eventBus.$emit('search', {})
    },
  }
}
</script>

<style lang="scss" scoped>
.suggestions {
  position: relative;
  &__pad {
    margin-bottom: $grid-gutter-width;
  }

  swiper-container {
    padding:0 $grid-gutter-width/2;
    swiper-slide {
      padding:0 $grid-gutter-width/2;
    }
  }  
  .arrow {
    position: absolute;
    top: 50%;
    transform:translateY(-50%);
    cursor:pointer;
    z-index: 10;
    &#prev-button {
      left:0;
  }
    &#next-button {
      right:0;
    }
  }
}
</style>