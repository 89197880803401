<template>
  <div class="homepage-view">

    <section class="section section--slider">
      <swiper-container init="false" ref="swiper-container" class="section--slider-main" pagination="true">
        <swiper-slide
          v-for="(item, item_index) in slider_items"
          :key="_uid+item_index"
          class="section--slider-item"
          :class="{ overlay: item.overlay }">
          <div class="section--slider-image" :style="'background-image:url('+item.wallpaper+')'" />
          <b-container class="captions">
            <vb-icon :name="'symbol_lb'" :size="36" class="icon" />
            <div class="text">
              <div class="vb-heading h2" v-html="item.title" />
              <div class="text-md" v-html="item.text" />
            </div>
          </b-container>
        </swiper-slide>
      </swiper-container>
      <vb-icon :name="'angle_left'" :color="'000'"  :size="18" class="section--slider-nav section--slider-nav--prev " @click="swiper_instance.swiper.slidePrev()" />
      <vb-icon :name="'angle_right'" :color="'000'" :size="18" class="section--slider-nav section--slider-nav--next" @click="swiper_instance.swiper.slideNext()" />
      <div class="custom-pagination">
        <div class="custom-pagination-item"
          v-for="(item, item_index) in Array.from({length: slider_items.length/2}, (_, i) => i)"
          :key="_uid+item_index"
          :class="{ active: slider_index == item_index }"
        />
      </div>
      <!--<vb-icon :name="'angle_left'" :size="20" class="arrow" id="prev-button" @click="swiper_instance.swiper.slidePrev()">PREV</vb-icon>
      <vb-icon :name="'angle_right'" :size="20" class="arrow" id="next-button" @click="swiper_instance.swiper.slideNext()">NEXT</vb-icon>-->
    </section>

    <!--booking engine-->
    <section class="section section--booking-engine" v-if="is_authenticated()">
      <b-container>
        <booking-engine @search="() => { this.$router.push({ name: 'results' }) }"/>
      </b-container>
    </section>

    <!--loader-->
    <section class="section section--loader" v-if="is_authenticated() && searching">
      <loader size="large" :show_timing_slot="true" />
    </section>

    <!--token expired-->
    <token-expired class="section section--token-expired" v-if="!is_authenticated()">
      <!--<template v-slot:header />-->
      <!--<template v-slot:content />-->
      <!--<template v-slot:footer />-->
    </token-expired>

    <!--static contents-->
    <section class="section">
    </section>

    <!--suggestions-->
    <section class="section" v-if="suggestions">
      <b-container>
        <suggestions :suggestions="suggestions" :autoplay="suggestions_autoplay_speed"/>
      </b-container>
    </section>

    <!--value props list-->
    <section class="section">
      <b-container>
        <point-list-01 
          :pl="[
            {
              pl_icon: 'shield',
              pl_title: 'Booking.com, il top del travel online',
              pl_text: 'Scopri le migliori proposte di viaggio dell’operatore leader globale delle prenotazioni online.',
            },
            {
              pl_icon: 'happy_pin',
              pl_title: 'Per il welfare aziendale',
              pl_text: 'Un servizio progettato per i lavoratori delle aziende italiane, dal sito web al servizio di assistenza',
            },
            {
              pl_icon: 'hands',
              pl_title: 'La nostra assistenza',
              pl_text: 'Un team di assistenza italiano con esperienza pluriennale di supporto ai titolari di welfare aziendale',
            },
            /*
            {
              pl_icon: 'heart',
              pl_title: 'Come in agenzia ma online',
              pl_text: 'Solo su ' + get_config().site.brand + ' ' + get_config().site.payoff + ' trovi un\'assistenza attenta e dedicata anche su webchat e WhatsApp',
            },
            */
          ]"/>
      </b-container>
    </section>

    <!--value props row
    <section class="section">
      <b-container>
        <value-proposition
          :vp="[
            {
              vp_image: 'assets/images/statics/iStock-1208856002.jpg',
              vp_content: [
                {
                  vp_icon: 'symbol_vv',
                  vp_title: 'Hai bisogno di un consiglio?',
                  vp_text: 'Se cerchi un suggerimento o la soluzione a tue specifiche esigenze, parla con un nostro consulente. <strong><a href='+get_config().site.phoneNumber+'>Chiamaci allo '+get_config().site.phoneNumber+'</a></strong>',
                },
                {
                  vp_icon: 'symbol_vv',
                  vp_title: 'Su di noi puoi contare!',
                  vp_text: 'Solo ' + get_config().site.brand + ' ' + get_config().site.payoff + ' ti offre un\'assistenza multicanale, telefonica, webchat, WhatsApp, email, webcall a portata di click e dedicata a gli utenti di welfare aziendale. <strong><a href=javascript:window.zE.activate()>Parla con noi!</a></strong>',
                },
              ]
            },
          ]"/>
      </b-container>
    </section>-->

  </div>
</template>

<script>
import { mapState } from 'vuex'
import TokenExpired from '@components/blocks/token-expired.vue'
import PointList01 from '@library/blocks/point-list-01.vue'
//import ValueProposition from '@library/blocks/value-proposition.vue'
import Suggestions from '@booking/components/blocks/suggestions.vue'
import { get_config, is_authenticated } from '@src/utilities'
import { md } from '@running_app_src/scss/abstract/_custom.scss'

export default {
  name: 'homepage-view',

  components: {
    'token-expired': TokenExpired,
    'point-list-01': PointList01,
    //'value-proposition': ValueProposition,
    'suggestions': Suggestions,
  },

  props: {
  },

  data() {
    return {
      slider_items: this.get_slider_items(),
      slider_index: 0,
      suggestions: get_config('searchSuggestions', []),
      suggestions_autoplay_speed: get_config('guiSettings.autoplaySpeedSuggestions', 0),
      swiper_instance: {},
      swiper_params: {
        slidesPerView: 1,
        loop: true,
        autoHeight: true,
        breakpoints: {
          [md.replace(/[^\d.]+/g, "")]: {
            slidesPerView: 2,
          },
        },
        on: {
          init: s => {
            // forzo l'aggiornamento senza cambiare slide per aggiornare la autoHeight
            // s.slideTo(0)
            // aggiorno slider_index per avere l'active nella paginazione
            this.slider_index = s.realIndex
            // aggiorno l'istanza in base al numero di slides:
            // se loopedSlides==1 sono nel mobile (default: chiamo la update() ma di fatto non succede niente)
            // se loopedSlides==2 sono nel desktop e devo manipolare l'ordine delle slides per mostrare correttamente l'ultima in trasparenza
            this.slider_items = this.get_slider_items(s.loopedSlides)
            s.update()
          },
          /*
          slideChange: event => {
            // aggiorno slider_index per avere l'active nella paginazione
            this.slider_index = event.realIndex
          },
          */
          slidePrevTransitionStart: () => {
            if (this.slider_index == 0) this.slider_index = (this.slider_items.length/2 - 1)
            else this.slider_index = this.slider_index-1
          },
          slideNextTransitionStart: () => {
            if (this.slider_index == (this.slider_items.length/2 - 1)) this.slider_index = 0
            else this.slider_index = this.slider_index+1
          },
        },
      },
    }
  },

  computed: {
    ...mapState(
      'livebk', [
        'searching',
      ]
    ),
  },

  created() {
  },

  mounted() {
    const swiper_instance = this.$refs['swiper-container']
    Object.assign(swiper_instance, this.swiper_params)
    swiper_instance.initialize()
    this.swiper_instance = swiper_instance
  },

  methods: {
    get_slider_items(slides = 1) {
      let items = is_authenticated() ? get_config().sliderHome.private : get_config().sliderHome.public
      if (slides == 1) return items
      let last = items.pop()
      return [last, ...items, last, ...items]
    },
  },
}
</script>

<style lang="scss" scoped>
  .section {
    &--slider {
      position: relative;
      margin-top: 0;
      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }
      &-main {
      }
      &-item {
        position: relative;
        &.overlay {
          //
        }        
      }
      &-image {
        background-size: cover;
        background-position: center center;
        height: 260px;
        @include media-breakpoint-up(md){
          height: 470px;
        }
        @include media-breakpoint-up(lg){
          height: 550px;
        }
      }
      &-nav {
        position: absolute;
        z-index: 1;
        top: 130px;
        transform: translateY(-50%);
        padding: $grid-gutter-width/2;
        cursor: pointer;
        mix-blend-mode: difference;

        &:hover {
          opacity: 1;
        }

        &--prev {
          left: 0;

          ::v-deep {
            svg {
              filter: drop-shadow(-2px 0 0 $white)
            }
          }
        }

        &--next {
          right: 0;

          ::v-deep {
            svg {
              filter: drop-shadow(2px 0 0 $white)
            }
          }
        }

        @include media-breakpoint-up(md){
          top: 50%;
        }       
      }
      .captions {
        margin-top: $grid-gutter-width/2;
        display: flex;
        .icon {
          padding-top: 3px;
        }
        .text {
          * {
            line-height: 120%;
          }
          padding-left: $grid-gutter-width/2;
          color: $dark;
          .vb-heading {
            margin-bottom: 4px;
            color: $dark;
            line-height: 110%;
          }
        }

        @include media-breakpoint-between(md,lg){
          padding-left: 16% !important;
          .vb-heading {
            font-size: 30px !important;
            max-width: 90% !important;
          }
        }
      }
      
      .custom-pagination {
        display: none;
        position: absolute;
        z-index: 1;
        left: calc(2.5% + 36px + #{$grid-gutter-width/2}); //margine sinistro + larghezza icona + padding contenitore captions
        bottom: 100px;
        &-item {
          margin-right: 10px;
          height: 2px;
          width: 30px;
          background: $gray-500;
          &.active {
            background: $gray-600;
          }
        }
      }

      @include media-breakpoint-up(md) {
        &-item {
          &:after {
            content:"";
            position: absolute;
            background: rgba($white,0.9);
            top:0;
            left:0;
            right:0;
            width:100%;
            height:100%;
            transition: $transition-base;
            opacity: 0;
          }          
        }

        .custom-pagination {
          display: flex;
          margin-left: 10.5%;
        }
        .swiper-slide-prev {
        }
        .swiper-slide-active {
          .captions {
            display: none;
          }
          &:after {
            opacity: 1;
          }
        }
        .swiper-slide-next {
          position: relative;
          .captions {
            position: absolute;
            top: 50%;
            left: 0%;
            right: 0%;
            transform: translateY(-50%) translateX(-100%);
            margin-top: 0;
            padding: 0 $grid-gutter-width 0 26%;
            .text-md {
              font-size: 18px;
            }
            .vb-heading {
              font-size: 36px;
              line-height: 120%;
            }
            .icon {
              padding-top: 8px;
            }
          }          
        }
      }
    }
    &--booking-engine {
      position: relative;
      z-index: 1;
      @include media-breakpoint-up(md) {
        margin-top: -33px;
        .booking-engine {
          margin-top: 0;
        }        
      }
    }
    &--token-expired {
      margin-top: $grid-gutter-width*2;
    }
  }
</style>